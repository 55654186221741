import { createFormActions, createInput, createInputFile, createSelect, createTextArea } from ":shared/components/form";
import { person_name } from ":shared/helpers/validations/user";

export function updateProfileInputs() {
  const Image = createInputFile(null, {
    required: false,
  });
  const FirstName = createInput("" as string, {
    required: false,
    validators: (value: string) => {
      const err = !!value && person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });

  const LastName = createInput("" as string, {
    required: false,
    validators: (value: string) => {
      const err = !!value && person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });

  const Email = createInput("", {
    required: false,
  });

  const Track = createInput("", {
    required: false,
  });

  const Major = createInput("", {
    required: false,
  });

  const Portfolio = createInput("", {
    required: false,
  });

  const Bio = createTextArea("", {
    required: false,
  });

  const Actions = createFormActions(
    {
      Image,
      FirstName,
      LastName,
      Email,
      Track,
      Major,
      Portfolio,
      Bio,
    },
    {
      defaultCase: "snake",
    }
  );

  return {
    Actions,
    Image,
    FirstName,
    LastName,
    Email,
    Track,
    Major,
    Portfolio,
    Bio,
  };
}
